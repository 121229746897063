@import 'src/assets/styles/common.scss';

.contact-section {
  position: relative;

  @include flex(center, center);
  padding: 125px 80px 60px;

  @include more-than-desktop {
    padding: 165px 80px 165px;
  }

  @include mobile {
    padding: 0;
  }
}

.contact-section-content {
  overflow: hidden;

  position:relative;

  width: 100%;
  max-width: 1440px;

  height: 477px;

  background: linear-gradient(180deg, #012052 0%, #011432 100%);

  border-radius: 24px;
  color: $gray-1;

  .contact-desktop {
    position: relative;

    height: 100%;


    margin: 0 auto;

    padding-top: 126px;
    padding-left: 80px;
  }

  .contact-title {
    @include font-barlow(40px, 50px, $font-bold);
    width: 64%;

    position: relative;
    z-index: 2;
  }

  .contact-text {
    @include font-lato(22px, 32px, $font-medium);
    color: $gray-5;
    width: 64%;

    position: relative;
    z-index: 2;

    margin-top: 8px;
  }

  .contact-actions {
    @include flex(center);

    margin-top: 40px;

    position: relative;
    z-index: 2;
  }

  .contact-btn {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  .contact-footer {
    margin-top: 24px;

    padding-left: 70px;

    @include flex;
  }

  .contact-scribble {
    @include font-nanum-pen(16px, 18px);
    width: 175px;

    z-index: 2;
  }


  @keyframes arrow-fill {
    from {
      stroke-dashoffset: -1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .contact-scribble-arrow {
    width: 140px;

    color: $gray-1;

    margin-right: 16px;

    stroke-dasharray: 1000;
    stroke-dashoffset: -1000;

    z-index: 2;
  }

  .contact-content.fade-done .contact-scribble-arrow {
    stroke-dashoffset: 0;
    animation: arrow-fill 1000ms ease-out 0ms;
  }
}

@keyframes contact-us-fade {
  0% {
    opacity: 0;
    transform: translateY(3%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.contact-media {
  position: absolute;

  //right: 100px;
  //bottom: 60px;

  right: 200px;
  bottom: 120px;

  //width: 432px;
  z-index: 2;

  @include more-than-desktop {
    right: auto;
    left: calc(50% + 325px);
    //width: 450px;
    bottom: 225px;
  }

  @include tablet-landscape {
    right: 156px;
  }

  @include mobile {
    display: none;
  }

  &.fade-trigger.fade-done {
    animation-name: contact-us-fade;
  }
}

.contact-media-frame.phone-frame {
  width: 246px;
  height: auto;

  --phone-frame-color: #2f2f2f;
  --phone-frame-width: 6px;
  --phone-frame-radius: 32px;
}

.contact-img {
  width: 100%;

  border-radius: calc(var(--phone-frame-radius) - var(--phone-frame-width));

  display: block;
}

.video-container.contact-video {
  @include size(100%);


  .video-container-el {
    border-radius: calc(var(--phone-frame-radius) - var(--phone-frame-width));

    object-fit: fill;
    object-position: center;
  }
}

.contact-section-blur-1 {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
}

.contact-section-blur-2 {
  position: absolute;
  bottom: 0;
  right: 0;

  height: 100%;
}

@include tablet-landscape {
  .contact-section-content {
    .contact-desktop {
      padding-top: 96px;
      padding-left: 48px;
    }

    .contact-title {
      width: 56%;
    }
  }
    .contact-media {
      right: 48px;
      bottom: 24px;
    }
}

@include tablet-portrait {
  .contact-section-content {
    //padding-top: 50px;

    height: 383px;

    .contact-title {
      @include font-barlow(32px, 30px, $font-bold);
    }

    .contact-img {
      right: 45px;
    }

    .contact-desktop {
      padding-top: 64px;
    }

    &::after {
      left: -400px;
    }

    &::before {
      right: -400px;
    }
  }
}

@include mobile {
  .contact-desktop {
    display: none;
  }

  .contact-section-content {
    border-radius: 0;

    padding: 32px 32px 0;
    height: auto;

    &::after {
      left: -500px;
    }

    &::before {
      display: none;
    }
  }
}

.contact-mobile  {
  @include size(100%);
  display: none;
}

@include mobile {
  .contact-mobile {
    display: block;
  }

  .contact-mobile-title {
    @include font-barlow(50px, 54px, $font-black);
    @include text-highlight(linear-gradient(72.05deg, #25AFFF 51.04%, #2D57E4 71.58%));

    text-align: center;

    margin-bottom: 8px;
  }

  .contact-mobile-subtitle {
    @include font-barlow(26px, 33px, $font-semi-bold);

    text-align: center;

    color: $gray-1;

    margin-bottom: 24px;
  }

  .contact-mobile .btn {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  .contact-mobile-media {
    position: relative;
    display: block;
    //width: 311px;

  }

  .contact-mobile-media-frame.phone-frame {
    margin: 40px auto 20px;

    width: 311px;
    height: auto;

    --phone-frame-color: #2f2f2f;
    --phone-frame-width: 6px;
    --phone-frame-radius: 32px;
  }

  .contact-mobile-img {
    display: block;
    width: 100%;

    border-radius: calc(var(--phone-frame-radius) - var(--phone-frame-width));
  }

  .contact-mobile-video {
    @include size(100%);

    .video-container-el {
      border-radius: calc(var(--phone-frame-radius) - var(--phone-frame-width));

      object-fit: fill;
      object-position: center;
    }
  }
}


